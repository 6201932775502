
 <template>

  <div>
    <!-- modal login-->
    
      <b-modal
      id="modal-select2"
      size="lg"
      :title="detailObj.title"
       centered


    >
    <div v-if="loading ==true">

    <b-spinner variant="primary" type="grow" style="margin-left:350px;" class="p-2"  label="Spinning"></b-spinner>
      </div>

        <div   v-if="loading ==false">

      <div>
        <b-container class="bv-example-row">
   <b-card>
 <b-row>
    <div lg="3" md="3" sm="12" cols="12">

       <div class="profile-img pb-1" >

        <!-- <div >
          <p v-if="detailObj.isApproved == 'pending' " >  <b-button variant="gradient-warning" class="btn-icon">
    Pending  <feather-icon icon="TagIcon" />  </b-button> </p>
              <p v-if="detailObj.isApproved == 'suspended' " >  <b-button variant="gradient-danger" class="btn-icon">
    Suspended  <feather-icon icon="TagIcon" />  </b-button> </p>

    <p v-if="detailObj.isApproved == 'true'"  >  <b-button variant="gradient-success" class="btn-icon float-right">
     <feather-icon icon="CheckCircleIcon" />  </b-button> </p>

      <p v-if="detailObj.isApproved == 'false'" >  <b-button variant="gradient-danger" class="btn-icon "  >
    Denined <feather-icon icon="XCircleIcon" />
  </b-button></p>
        </div> -->

        <b-card-img  :src="detailObj.coverImg" alt="Image" class="rounded-1" height="110px" ></b-card-img>




    </div>
    </div>


    <b-col  lg="6" md="6"  sm="12" cols="12">
 <h3 > {{detailObj.title}}  </h3>
  <b-row>
     <b-col>
    <p >   {{detailObj.description}}   </p>
     </b-col>
 </b-row>
    </b-col>
    <b-col  lg="3" md="3" sm="12" cols="12" >
    <div class=""  >
          <p v-if="detailObj.isApproved == 'pending' " >  <b-button variant="gradient-warning" class="btn-icon">
    Pending  <feather-icon icon="TagIcon" />  </b-button> </p>
              <p v-if="detailObj.isApproved == 'suspended' " >  <b-button variant="gradient-danger" class="btn-icon">
    Suspended  <feather-icon icon="TagIcon" />  </b-button> </p>

    <p v-if="detailObj.isApproved == 'true'"  >  <b-button variant="gradient-success" class="btn-icon ">
 Approved    <feather-icon icon="CheckCircleIcon" />  </b-button> </p>

      <p v-if="detailObj.isApproved == 'false'" >  <b-button variant="gradient-danger" class="btn-icon "  >
    Denined <feather-icon icon="XCircleIcon" />
  </b-button></p>
        </div>

    </b-col>

  </b-row>
   </b-card>


  <b-card>
<b-row>
    <b-col lg="4" md="4" cols="12">
    <h3>Category</h3>
    </b-col>
     <b-col lg="4" md="4" cols="12">
    <h3>Filling</h3>
    </b-col>
       <b-col lg="4" md="4" cols="12">
    <h3>Days required</h3>
    </b-col>
 </b-row>
<b-row>
   <b-col lg="4" md="4" cols="12">
    <p>{{detailObj.Name}}</p>

    </b-col>
     <b-col lg="4" md="4" cols="12">
  <p>{{detailObj.fillingName}}</p>
    </b-col>
       <b-col lg="4" md="4" cols="12">
   <p>{{detailObj.minimumTime}}</p>
    </b-col>
</b-row>
  </b-card>

 <b-card>
 <b-row>
    <b-col cols="12">
    <h3>Flavours</h3>
    </b-col>
 </b-row>
<b-row>

       <div v-for="flv in flavour[0]"
        v-bind:key="flv.flavourId">
         <b-col cols="12">
        <p  >{{flv.flavourName}}</p>
   </b-col>
         </div>





</b-row>
</b-card>
<b-card>
 <b-row>
    <b-col cols="12">
    <h3>Sizes</h3>
    </b-col>
 </b-row>
  <b-row>




        <div v-for="flv in cakeSize[0]"
        v-bind:key="flv.sizeId">
 <b-col cols="12">
        <p >{{flv.size}} - {{ flv.cost + "AED"}}</p>
   </b-col>
        </div>


</b-row>
</b-card>


<div v-if="detailObj.totalLayer > 1">
   <b-card>
<b-row>
    <b-col lg="4" md="4" sm="4" cols="5">
    <h3>Layers</h3>
    </b-col>
    <b-col lg="7" md="7" sm="7" cols="7">
    <h3>Total ({{detailObj.totalLayer }})</h3>
    </b-col>
 </b-row>
  <b-row>



 <b-col cols="12">
        <div v-for="flv in cakeLayers"
        v-bind:key="flv.cakeLayersId">


          <b-row>

       <b-col>
      <h6> Dummy layer </h6> <p> {{flv.dumyLayer}}   </p>
       </b-col>
       <b-col>
      <h6> Cake layer </h6> <p> {{flv.cakeLayer1}} </p>
       </b-col>
       <b-col>
     <h6> Layer price </h6> <p> AED {{flv.price}}  </p>
       </b-col>
          </b-row>

        </div>
   </b-col>

</b-row>
 </b-card>
</div>




 <b-card>
  <b-row>
    <b-col cols="12">

       <h3 style="margin-left:5px;" >Images </h3>
 <gallery :images="cakeImages" :index="index" @close="index = null"></gallery>
    <div
      class="image rounded-1"
      v-for="(image, imageIndex) in cakeImages"
      :key="imageIndex"
      @click="index = imageIndex"
      :style="{ backgroundImage: 'url(' + image + ')', width: '150px', height: '110px' }"
    ></div>

    </b-col>

  </b-row>
 </b-card>
<b-row>
  <b-col cols="4">

    </b-col>

    <b-col cols="4">
         <b-button variant="gradient-danger " class="ml-4"  @click="DeleteProduct(detailObj.productId)">
    Delete  </b-button>
    </b-col>
   <b-col cols="4">

    </b-col>

  </b-row>


</b-container>


      </div>



      </div>


    </b-modal>

   <div
      no-body
      class="mb-2"
      style="padding:20px"
      cols="12" md="4" sm="12"

    >
    <div class="">

        <!-- Table Top -->
        <b-row class="text-center">
           <!-- Search -->


                 <b-col>
<!-- 
                <b-button

                @click="AddProduct()"
                variant="primary"
                >
                  <span class="text-nowrap">Add Product</span>
                </b-button>
            <b-button
              class="ml-1"
              v-b-modal.modal-login
              variant="primary"

                >
                  <span class="text-nowrap">Filters</span>
                </b-button> -->
             
        </b-col>


              <b-col cols="7" >

                 <b-form-input
                v-model="searchQuery"
                class="d-inline-block "
                placeholder="Search..."
              />


           </b-col>

           <b-col >

                <!-- <b-form-radio-group
                v-model="itemView"
                class=" list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="20"
                  />
                </b-form-radio>
              </b-form-radio-group> -->


           </b-col>





          <!-- Per Page -->

        </b-row>
      </div>
    <!-- :search-options="{
        enabled: true,
        externalQuery: searchTerm }" -->
        <!-- :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" -->
<!--         
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }" -->
  <div class="mt-2">
    <b-card>
      <b-row>
    <b-col cols="8">
          <b-button
      size="sm"
      class="mr-1 mb-1"
      variant="outline-primary"
      @click="selectAllRows"
    >
      Select all
    </b-button>
    <b-button
      size="sm"
      class="mr-1 mb-1"
         variant="outline-secondary"
      @click="clearSelected"
    >
      Clear selected
    </b-button>
    </b-col>
    <b-col cols="4">
    
   
       <b-button
      v-if="selected.length != 0"
      size="sm"
      class="mr-1 mb-1"
      variant="success"
      @click="DeletedAll(false)"
    >
     Recover : {{selected.length }} 
    </b-button>
    </b-col>
  </b-row>
    
       <b-table
      :tbody-tr-class="rowClass"
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="rows"
      :fields="columns"
      responsive
      hover
      
      @row-selected="onRowSelected"
    >
    
      <template #cell(coverImg)="data">
      <b-img  :src="data.value" style="width: 75px; height: 75px" rounded alt="Rounded image"/>
      </template>
      <template #cell(title)="data">
         <span> <strong> {{data.item.title}} </strong> </span>
        </template>
          <template #cell(price)="data">
         <span> <strong>  AED {{data.item.price}} </strong> </span>
        </template>
      <!-- Example scoped slot for select state illustrative purposes -->
      <template  #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <i class="feather icon-disc primary" />
        </template>


        
      </template> 
      

          <template #cell(actions)="data">
              <b-button
                variant="outline-success"
                class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
               @click="DeleteProduct(data.item.productId)"
              >
              
                <feather-icon
                  icon="RepeatIcon"
                  class="mr-50"
                 
                />
                <span>Recover Product</span>
              </b-button>
              <b-button
              hidden
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="ProductEdit(data.item.productId)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                
              </b-button>
            

              </template>
       <!-- <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template> -->
    </b-table>
    </b-card>
 
      </div>

      <div v-if="productEmpty == true">
  <b-alert show variant="secondary" class="p-2">No Data</b-alert>

      </div>
  

      <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalOrders"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

   </div>
   </div>
</template>

<script>
import {
  BRow, BCol, BCard,BCardBody, BFormInput, BCardText, BCardTitle, BButton, BPagination, BDropdown, BDropdownItem,BAlert,BSpinner,BCardImg,BImg,BFormRadioGroup,BFormRadio,BAvatar,BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
//import useProductsList from './useProductsList'
//import ProductListAddNew from './ProductListAddNew.vue'
import axios from '@axios'
import { BFormSelect } from 'bootstrap-vue'
import VueGallery from 'vue-gallery';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { VueGoodTable } from 'vue-good-table'
export default {
  props:['title'],
  components: {
   // flatPickr,
    //vSelect,
     BTable,
     //VueGoodTable,
    //BAvatar,
    BFormInput,
    BCard,
    //BCardText,
    BRow,
    //BCardBody,
    //BFormRadioGroup,
    //BFormRadio,
    BCol,
    BButton,
    BPagination,
    //BDropdown,
    //BDropdownItem,
    BCardImg,
    BImg,
    //BFormSelect,
    BAlert,
    BSpinner,
  'gallery': VueGallery
  },

  // setup() {
  //   const PRODUCT_APP_STORE_MODULE_NAME = 'app-product'

  //   // Register module
  //   if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, productStoreModule)

  //   // UnRegister on leave
  //   onUnmounted(() => {
  //     if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME)
  //   })

  //   //const isAddNewProductSidebarActive = ref(false)

  //   const categoryOptions = [
  //     { label: 'Eid Cakes', value: 'eid' },
  //     { label: 'Wedding Cake', value: 'wedding' },
  //     { label: 'Party Cake', value: 'party' },
  //   ]

  //    const flavorOptions = [
  //     { label: 'Chocolate', value: 'Chocolate' },
  //     { label: 'Oreo', value: 'oreo' },
  //     { label: 'Cheese', value: 'cheese' },
  //     { label: 'Banana', value: 'banana' },
  //   ]

  // // const {
  // //   searchQuery,
  // // } = useProductsList()
  //   return {

  //     // Sidebar
  //     //isAddNewProductSidebarActive,
  //     // Filter
  //     avatarText,
  //     categoryOptions,
  //     flavorOptions,
  //    // searchQuery,
  //   }
  // },

  created()
  {
    this.LoadData();
    console.log("asa"+ this.selected); 
  },
  computed:
  {
    filterProducts:function()
    {
      return this.myProducts.filter((pro)=>
      {
      return  pro.title.toLowerCase().match(this.searchQuery.toLowerCase())
      } )
    }
  },
  mounted() {
  //  axios
  //         .get('/apps/product/products', { params: "" })
  //         .then((response)=> {
  //           console.log(response);
  //         if (response.data.status === "error") {
  //         } else {
  //           this.myProducts = response.data;
  //           console.log(this.myProducts);
  //         }
  //       })
  //         .catch(error => reject(error))
  },
  data(){
    return {
      deleteObj:
      {
      productIds:[],
      isDeleted:""
      },
      statusObj:
      {
      productIds:[],
      status:""
      },
      
       selected: [],
       rows: [],
       columns: [
        {
          label: '',
          key: 'selected',
        },
        {
          label: '',
          key: 'coverImg',
        },
        {
          label: '',
          key: 'title',
        },
        {
          label: '',
          key: 'price',
        },
         {
          label: '',
          key: 'actions',
        },
       
        
      ],
      itemViewOptions:[
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
  ],
      itemView:'grid-view',
      searchQuery:'',
      loading:false,
      filterData:'',
      date:{
        range:''
      },
      detailObj:{},
      index:null,
       rangeDate: null,
       productfilterOptions: [
          { text: 'Most sold product', value: 'mostSold' },
          { text: 'Least sold product', value: 'leastSold' },
          { text: 'Most popular product', value: 'mostPopular' },
          { text: 'Least popular product', value: 'leastPopular' },
          { text: 'Most profitable product', value: 'mostProfitable' },
        ],
        cakeLayer:[],
     myProducts: [

     ],
     productEmpty:false,
      myObj: {
        id: 0,
        name: "",
        desscription: "",
        flavor: "",
        image_url: "",
        price: "",
        serving: "",
        weight: "",
        category: "",
        vendor: "",

      },
      cakeImages:[],
      flavour:[],
     cakeSize:[],
    };
  },
  methods: {
    rowClass(item, type) {
      const colorClass = 'table-secondary';
      if (!item || type !== 'row') {
        return;
      }

      // eslint-disable-next-line consistent-return
      //console.log("Hello", item);
      if ( item.status == 'inactive') 
      {
        return colorClass;
      }
     
    },
     DeletedAll(deleted)
    {
      this.deleteObj.productIds = [];
    this.selected.forEach(elem=> {
      this.deleteObj.productIds.push(elem.productId);
    })
      this.deleteObj.isDeleted = deleted;
Swal.fire({
  title: 'Are you sure?',
  text: "You won't be able to revert this!",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes, Recoverd it!'
}).then((result) => {
  if (result.isConfirmed)
  {
  var axios = require('axios');

  var config = {
    method: 'put',
    url: 'https://okaaik.fastech.pk/api/products/RecoverDeleted',
    headers: {
      'Authorization': 'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI'
    },
     data : this.deleteObj
  };

  axios(config)
  .then( (response) => {
    console.log(response.data);
    if(response.data.status === "success")
     {
      Swal.fire(
      'Recoverd!',
      'this products has been recoverd.',
      'success'
    ).then((res)=>{

      this.LoadData();
      this.$bvModal.hide('modal-select2');
    })
     }
     else
     {
      Swal.fire(
      'Error!',
      'Something went wrong.',
      'error'
    )
     }

     console.log(response.data);

 })
  .catch(function (error) {
    console.log(error);
  });
  }


});

    
    },
    ActiveAll(status)
    {
      this.statusObj.productIds = [];
    this.selected.forEach(elem=> {
      this.statusObj.productIds.push(elem.productId);
    })
    this.statusObj.status = status;
    ///console.log()
     var axios = require('axios');

  var config = {
    method: 'post',
    url: 'https://okaaik.fastech.pk/api/products/ActiveAll',
    headers: {
      'Authorization': 'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI'
    },
      data : this.statusObj
  };

  axios(config)
  .then( (response) => 
  {

    if(response.data.status == "success")
    {
    this.LoadData();
    this.$bvToast.toast('Status updated.', {
            title: "Success!",
            variant: 'success',
            toaster:  'b-toaster-bottom-center',
      })

    }
   console.log(response.data.data); 

 })
  .catch(function (error) {
    console.log(error);
  });

    
    },
       onRowSelected(items) 
       {
      this.selected = items
      console.log( this.selected);
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    AddProduct()
    {
      this.$store.state.userData.productId = 0;
      console.log("storeId", this.$store.state.userData.productId);
      this.$router.push('/apps/products/add');

    },
    ProductEdit(id)
    {
      this.$store.state.userData.productId = id

      console.log("storeId",this.$store.state.userData.productId);
       this.$router.push('/apps/products/add');
    },
    Filter()
    {
     // console.log("Osama");
      console.log(this.date.range , this.filterData.value);
      var split = this.date.range.split(" ");
      var from = split[0];
      var to = split[2];

       //console.log(from );
    },
    ProductDetails(id)
    {
      this.loading= true;
      this.cakeImages = [];
      this.cakeLayers =[];
      this.cakeSize =[];
      this.flavour = [];
      console.log(id);
      var axios = require('axios');

  var config = {
    method: 'get',
    url: 'https://okaaik.fastech.pk/api/products/GetproductbyId/'+ id,
    headers: {
      'Authorization': 'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI'
    }
  };

  axios(config)
  .then( (response) => {

    //this.cakeImages.push(response.data.images);
     console.log( response.data);
     this.cakeImages=[];
     response.data.images.forEach(elem => {
              this.cakeImages.push(elem.imagePath);
            })
    this.cakeSize.push(response.data.cakesize);
    this.flavour.push(response.data.flavours);
    this.detailObj = response.data.data[0];
    this.cakeLayers = response.data.cakelayer;
    console.log("Mylayers" ,this.cakeLayers);
    console.log(this.detailObj);
    console.log(this.cakeImages);
    console.log(this.cakeSize[0]);

    console.log(this.flavour[0]);
      this.loading= false;
     //console.log( response.data);

 })
  .catch(function (error) {
    console.log(error);
  });
    },
DeleteProduct(id)
  {
    this.deleteObj.productIds = [];
    this.deleteObj.productIds.push(id);
    this.deleteObj.isDeleted = false;
Swal.fire({
  title: 'Are you sure?',
  text: "You won't be able to revert this!",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes, Recover it!'
}).then((result) => {
  if (result.isConfirmed)
  {
  var axios = require('axios');

  var config = {
    method: 'put',
    url: 'https://okaaik.fastech.pk/api/products/RecoverDeleted',
    headers: {
      'Authorization': 'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI'
    },
     data : this.deleteObj
  };

  axios(config)
  .then( (response) => {
    console.log(response.data);
    if(response.data.status === "success")
     {
      Swal.fire(
      'Recoverd!',
      'Your product has been recoverd.',
      'success'
    ).then((res)=>{

      this.LoadData();
      this.$bvModal.hide('modal-select2');
    })
     }
     else
     {
      Swal.fire(
      'Error!',
      'Something went wrong.',
      'error'
    )
     }

     console.log(response.data);

 })
  .catch(function (error) {
    console.log(error);
  });
  }


});

  },

       LoadData() {
  var axios = require('axios');

  var config = {
    method: 'get',
    url: 'https://okaaik.fastech.pk/api/products/RecentlyDeletedProducts/'+ this.$store.state.userData.userID,
    headers: {
      'Authorization': 'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI'
    }
  };

  axios(config)
  .then( (response) => {
    // console.log(JSON.stringify(response.data));

    //  this.data  =  response.data;
     //console.log( response.data);

    this.myProducts = response.data.data;
    this.rows = response.data.data;

     console.log(this.myProducts);
     console.log(response.data);

  if(this.myProducts.data.length == 0)
   {
     this.productEmpty = true;
   }
 })
  .catch(function (error) {
    console.log(error);
  });
    }


  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
<style scoped>
  .image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    margin: 5px;
  }
</style>

